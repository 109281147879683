<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-2y"
            defaultEnd="2M"
            label="공포기간"
            name="promulgationPeriod"
            v-model="searchParam.promulgationPeriod"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            stepperGrpCd="MDM_LAW_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="mdmLawStepCd"
            label="진행단계"
            v-model="searchParam.mdmLawStepCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field
            label="검토자"
            name="checkUserId"
            v-model="searchParam.checkUserId" />
        </div>
      </template>
    </c-search-box>
    <c-table
      title="법령검토 목록"
      tableId="lawMakingCheck"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openPop" /> 
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="props.row.lawLink">
          <q-btn
            class="tableinnerBtn"
            flat
            icon="launch"
            align="center"
            color="blue-6"
            label=""
            @click.stop="linkClick(props.row, col)" />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'law-review',
  data() {
    return {
      searchParam: {
        promulgationPeriod: [],
        mdmLawStepCd: null,
        checkUserId: '',
      },
      grid: {
        columns: [
          {
            name: 'mdmLawStepName',
            field: 'mdmLawStepName',
            label: '진행단계',
            align: 'center',
            sortable: false,
            style: 'width:80px',
            type: 'link',
          },
          {
            name: 'acceptDeptNames',
            field: 'acceptDeptNames',
            label: '적용부서',
            align: 'left',
            sortable: false,
            style: 'width:130px',
          },
          {
            name: 'checkUserNames',
            field: 'checkUserNames',
            label: '검토자',
            align: 'left',
            sortable: false,
            style: 'width:130px',
          },
          {
            name: 'lawNameKor',
            field: 'lawNameKor',
            label: '법령명한글',
            align: 'center',
            sortable: false,
            style: 'width:120px',
          },
          {
            name: 'lawNameAbb',
            field: 'lawNameAbb',
            label: '법령약칭명',
            align: 'center',
            sortable: false,
            style: 'width:100px',
          },
          {
            name: 'promulgationDate',
            field: 'promulgationDate',
            label: '공포일자',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'revisiontypename',
            field: 'revisiontypename',
            label: '제개정',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'competentMinistriesName',
            field: 'competentMinistriesName',
            label: '소관부처',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'lawClassName',
            field: 'lawClassName',
            label: '법령구분',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'enforcementDate',
            field: 'enforcementDate',
            label: '시행일자',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          // {
          //   name: 'lawTypeFlag',
          //   field: 'lawTypeFlag',
          //   label: '자법타법여부',
          //   align: 'center',
          //   sortable: false,
          //   style: 'width:80px',
          // },
          {
            name: 'custom',
            field: 'custom',
            label: '법령상세링크',
            align: 'center',
            sortable: false,
            style: 'width:80px',
            type: 'custom'
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.lawCheck.check.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col) {
      if (col.name === 'mdmLawStepName') {
        this.openPop(row)
      } else if (col.name === 'custom') {
        window.open('http://www.law.go.kr' + row.lawLink, '법규', 'width=1200, height=700');
      }
    },
    openPop(row) {
      this.popupOptions.title = '법령 검토';  
      this.popupOptions.param = {
        mdmLawCheckId: row ? row.mdmLawCheckId : '',
      };
      this.popupOptions.target = () => import(`${'./lawReviewDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
